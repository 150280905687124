import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { COOKIE_KEY } from '@zaritalk/constants';

const useReferrerCookie = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      const currentPath = window.location.href;
      Cookies.set(COOKIE_KEY.ZARITALK_REFERRER, currentPath, { sameSite: 'lax' });
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);
};

export default useReferrerCookie;
