export const MAP_ROUTES = {
  VACANCY_MAIN: '/vacancy',
  VACANCY_LIST: '/vacancy/list',
  VACANCY_SEARCH: '/vacancy/search',
  VACANCY_RECOMMEND: '/vacancy/recommend',
  VACANCY_BOOKMARK: '/vacancy/bookmark',
  VACANCY_DETAIL: '/vacancy/detail',
  VACANCY_DETAIL_DONE: '/vacancy/detail/done',
  REAL_ESTATE_MAP: '/real-estate-map',
  REAL_ESTATE_MAP_SEARCH: '/real-estate-map/search',
} as const;

export const SHORT_TERM_VACANCY_ROUTES = {
  MY_ROOM_SHORT_TERM_NEW: '/my/room/short-term/new',
  MY_ROOM_SHORT_TERM_PROFILE_NEW: '/my/room/short-term/profile',
  getMyRoomShortTermVacancy: ({ shortTermVacancyPk }: { shortTermVacancyPk: number }) =>
    `/my/room/short-term/${shortTermVacancyPk}`,
  getMyRoomShortTermModify: ({ shortTermVacancyPk }: { shortTermVacancyPk: number }) =>
    `/my/room/short-term/${shortTermVacancyPk}/modify`,
};

export const LESSEE_ROUTES = {
  ...MAP_ROUTES,
  ...SHORT_TERM_VACANCY_ROUTES,
  INDEX: '/',
  ALARM: '/alarm',
  ANONYMOUS_TALK: '/anonymous-talk',
  ANONYMOUS_TALK_NEW: '/anonymous-talk/new',
  ANONYMOUS_TALK_MY_ACTIVITY: '/anonymous-talk/my/activity',
  getAnonymousTalkDetail: ({ anonymousTalkPk }: { anonymousTalkPk: number }) =>
    `/anonymous-talk/${anonymousTalkPk}/detail`,
  getAnonymousTalkModify: ({ anonymousTalkPk }: { anonymousTalkPk: number }) =>
    `/anonymous-talk/${anonymousTalkPk}/modify`,
  getAnonymousTalkBuildingFeed: ({ residenceBuildingPk }: { residenceBuildingPk: number }) =>
    `/anonymous-talk/building/${residenceBuildingPk}`,
  getAnonymousTalkTownFeed: ({ residenceTownPk }: { residenceTownPk: number }) =>
    `/anonymous-talk/town/${residenceTownPk}`,
  getAnonymousTalkCategoryFeed: ({ tagName }: { tagName: string }) => `/anonymous-talk/category/${tagName}`,
  BILL_COMPLETE: '/bill/complete',
  BILL_CONTACT_NEW: '/bill/contact-new',
  BILL_KEY_MONEY_NEW: '/bill/key-money-new',
  BILL_REVIEW_REFERRAL_NEW: '/bill/review-referral-new',
  BILL_NEW: '/bill/new',
  BILL_NOTICE: '/bill/notice',
  BILL_PAY_NEW: '/bill/pay-new',
  BILL_STORE_NEW: '/bill/store-new',
  BILL_PREV_NEW: '/bill/prev-new',
  BILL_NON_RENTER_NEW: '/bill/non-renter-new',
  BILL_WORKING: '/bill/working',
  BILL_CONTRACT: '/bill/contract',
  BILL_EXTENDS: '/bill/extends',
  BILL_MODIFY: '/bill/modify',
  BILL_MULTI_NEW: '/bill/multi/new',
  BILL_ONBOARDING_NEW: '/bill/onboarding/payment-date-new',
  DENY_NOT_LESSEE: '/deny/not-lessee',
  ELECTRONIC_DOCUMENT: '/electronic-document',
  ELECTRONIC_DOCUMENT_NOT_FOUNT: '/electronic-document/not-found',
  ELECTRONIC_DOCUMENT_NOTICE: '/electronic-document/notice',
  ELECTRONIC_DOCUMENT_RECEIVE: '/electronic-document/receive',
  KAKAO_KEY_MONEY: '/kakao/key-money',
  KAKAO_PAY: '/kakao/pay',
  KAKAO_SIGN_UP: '/kakao/signUp',
  LEASE_REPORT: '/lease-report',
  LEASE_REPORT_DOWNLOAD: '/lease-report/download',
  MORE: '/more',
  MY_ROOM: '/my/room',
  MY_ROOM_NEW: '/my/room/new',
  PAY: '/pay',
  PAY_COMPLETE: '/pay/complete',
  PAY_CONFIRM: '/pay/confirm',
  PAY_FAIL: '/pay/fail',
  PAYMENT_LIST: '/pay/payment-list',
  PAY_LANDING: '/pay/landing',
  PAY_KAKAO_SYNC: '/pay/kakaoSync',
  PAY_PAYMENT_HISTORY_CERTIFICATE: '/pay/payment-history-certificate',
  REAL_RESIDENCE_REVIEW: '/real-residence-review',
  REAL_RESIDENCE_REVIEW_NEW: '/real-residence-review/new',
  REAL_RESIDENCE_REVIEW_AUTH: '/real-residence-review/auth',
  REAL_RESIDENCE_REVIEW_SEARCH: '/real-residence-review/search',
  REAL_RESIDENCE_REVIEW_BUILDING_FEED: '/real-residence-review/building',
  REAL_RESIDENCE_REVIEW_TOWN_FEED: '/real-residence-review/town',
  REAL_RESIDENCE_REVIEW_EVENT_INTRO: '/real-residence-review/event-intro',
  REAL_RESIDENCE_REVIEW_EVENT_INTRO_MIDDLEWARE: '/real-residence-review/event-intro/middleware',
  REAL_RESIDENCE_REVIEW_REFERRAL_INTRO: '/real-residence-review/referral-intro',
  REAL_RESIDENCE_REVIEW_REFERRAL_INTRO_MIDDLEWARE: '/real-residence-review/referral-intro/middleware',
  REAL_RESIDENCE_REVIEW_PHOTO_EVALUATION: '/real-residence-review/photo-evaluation',
  REAL_RESIDENCE_REVIEW_PHOTO_EVALUATION_COMPLETE: '/real-residence-review/photo-evaluation/complete',
  REFUND: '/refund',
  SETTING: '/setting',
  ADMIN: '/admin',
  DEEPLINK: '/deeplink',
  INDUCEMENT_APP: '/inducement-app',
  INTENT_REDIRECT: '/intent-redirect',
  KAKAO_INTRO: '/kakaoIntro',
  KAKAO_INTRO_B: '/kakaoIntroB',
  KAKAO_SYNC: '/kakaoSync',
  LOGOUT: '/logout',
  ONELINK: '/onelink',
  REDIRECT: '/redirect',
  REGISTER_BILL: '/register-bill',
  SERVER_MAINTENANCE: '/server-maintenance',
  TALK_VIEW: '/talk-view',
  WITHDRAWAL: '/withdrawal',
  SHORTENER: '/s',
  STACK_DEEPLINK: '/stack-deeplink',
  POINT_SHOP: '/point-shop',
  PAY_RECURRING_NEW: '/pay/recurring/new',
  PAY_RECURRING_LIST: '/pay/recurring/list',
  getRecurringPayModify: ({ recurringPayPk }: { recurringPayPk: number }) => `/pay/recurring/${recurringPayPk}/modify`,
  IN_APP_REVIEW_MIDDLEWARE: '/in-app-review/middleware',
  BENEFIT: '/benefit',
  ONBOARDING: '/onboarding',
} as const;

export const LESSOR_ROUTES = {
  ...MAP_ROUTES,
  ...SHORT_TERM_VACANCY_ROUTES,
  INDEX: '/',
  ALARM: '/alarm',
  MEMO: '/memo',
  MY_ROOM: '/my/room',
  MY_ROOM_SHORT_TERM: '/my/room?type=shortTerm',
  MY_ROOM_NEW: '/my/room/new',
  getModifyMyRoom: (vacancyPk: number) => `/my/room/${vacancyPk}/modify`,
  ELECTRONIC_DOCUMENT: '/electronic-document',
  ELECTRONIC_DOCUMENT_FORM_SYSTEM: '/electronic-document/form/system',
  BILL_SETTING: '/bill-setting',
  SCHEDULE: '/schedule',
  SUB_ACCOUNT: '/sub-account',
  COMMUNITY_MYPAGE: '/community/mypage',
  COMMUNITY: '/community',
  getCommunityPostDetail: (postPk: number) => `/community/post/${postPk}`,
  COMMUNITY_POST_NEW: '/community/post/new',
  HOST: '/host',
  HOST_SPARE: '/host/spare',
  HOST_SPARE_APP: '/host/spare/app',
  HOST_CONTACT_NEW: '/host/contact/new',
  HOST_CONTACT_LESSEE_BILL: '/host/contact/lessee-bill',
  ONBOARDING_LESSEE_BILL: '/onboarding/lessee-bill',
  ONBOARDING_LESSEE_BILL_CHECK: '/onboarding/lessee-bill/check',
  ONELINK: '/onelink',
  INITIAL_APP_INTRO: '/intro/initial-app',
  MORE: '/more',
  STACK_DEEPLINK: '/stack-deeplink',
} as const;

export const FRONT_API_GATEWAY_ROUTES = {
  TEST: '/tests',
  ERROR_REPORT: '/error-reports',
};

export const REALTOR_ROUTES = {
  INDEX: '/',
  VACANCY_LIST: '/vacancy/list',
  VACANCY_DETAIL: '/vacancy',
  VACANCY_CANCEL: '/vacancy/cancel',
  PREEMPTION_LIST: '/preemption/list',
  MY_ROOM: '/my/room',
  MY_ROOM_NEW: '/my/room/new',
  BROKERAGE_LIST: '/brokerage/list',
  MORE: '/more',
  REALTOR_PROFILE: '/realtor/profile',
  REALTOR_FIRST: '/realtor/first',
  STACK_DEEPLINK: '/stack-deeplink',
  KAKAO_SIGN_UP: '/kakao/signUp',
  LESSEE_PAYMENT: '/lessee/payment',
} as const;
