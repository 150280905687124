import uaParserJS from 'ua-parser-js';

export const getBrowserVendor = (userAgent?: string): string => {
  const agent = isClientSide() ? window.navigator.userAgent : userAgent ?? '';
  const agentLowerCase = agent.toLowerCase();

  if (agentLowerCase.search('Trident') !== -1 || agentLowerCase.indexOf('msie') !== -1) return 'Explorer Browser';
  if (agentLowerCase.indexOf('chrome') !== -1) return 'Chrome Browser';
  if (agentLowerCase.indexOf('safari') !== -1) return 'Safari Browser';
  if (agentLowerCase.indexOf('firefox') !== -1) return 'FireFox Browser';

  return '기타 브라우저';
};

export const getDeviceType = (userAgent?: string): string => {
  const agent = isClientSide() ? window.navigator.userAgent : userAgent ?? '';
  const agentLowerCase = agent.toLowerCase();
  const zaritalkAppUserAgentMatch = agentLowerCase.match(/zaritalkapp\S([0-9.]*)/);
  const appVersion = zaritalkAppUserAgentMatch?.length === 2 ? zaritalkAppUserAgentMatch[1] : '';

  const uaParser = uaParserJS(agent);
  const { device, os } = uaParser;

  let deviceType = '';

  if (isApp(agentLowerCase)) {
    deviceType += 'app_';
  }
  if (isAndroid(agentLowerCase)) {
    deviceType += 'android';
  }
  if (isIphone(agentLowerCase)) {
    deviceType += 'ios';
  }

  deviceType = deviceType === '' ? 'web' : deviceType;

  if (device.model) {
    deviceType += `/${device.model}`;
  }
  if (os.version) {
    deviceType += `/os_${os.version}`;
  }
  if (appVersion) {
    deviceType += `/app_${appVersion}`;
  }

  return deviceType;
};

export const isClientSide = (): boolean => {
  return typeof window !== 'undefined';
};

export const isServerSide = (): boolean => {
  return !isClientSide();
};

const ZARITALK_APP_REGEX = /zaritalkapp\S([\d.]*)/;

export const isApp = (userAgent?: string): boolean => {
  const agent = isClientSide() ? window.navigator.userAgent : userAgent ?? '';
  const agentLowerCase = agent.toLowerCase();

  return agentLowerCase.match(ZARITALK_APP_REGEX) !== null;
};

const IOS_REGEX = /iphone\s([\d.]*)/;

export const isIphone = (userAgent?: string): boolean => {
  const agent = isClientSide() ? window.navigator.userAgent : userAgent ?? '';
  const agentLowerCase = agent.toLowerCase();

  return agentLowerCase.match(IOS_REGEX) !== null;
};

const ANDROID_REGEX = /android\s([\d.]*)/;

export const isAndroid = (userAgent?: string): boolean => {
  const agent = isClientSide() ? window.navigator.userAgent : userAgent ?? '';
  const agentLowerCase = agent.toLowerCase();

  return agentLowerCase.match(ANDROID_REGEX) !== null;
};

export const makeDeviceInfo = () => {
  const device = getDeviceType();

  if (device === 'web') return `${device} | ${getBrowserVendor()}`;

  return isApp() ? `${device} | App` : `${device} | ${getBrowserVendor()}`;
};
