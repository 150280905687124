import { styled } from '@zaritalk/panda-css/jsx';

export const Center = styled('section', {
  base: {
    position: 'absolute',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
  },
});

// Column
export const ColCenter = styled('div', {
  base: {
    flexCol: 'CENTER',
    width: '100%',
  },
});

export const ColStartCenter = styled('div', {
  base: {
    flexCol: 'START_CENTER',
    width: '100%',
  },
});

export const ColEndCenter = styled('div', {
  base: {
    flexCol: 'END_CENTER',
    width: '100%',
  },
});

export const ColBetweenCenter = styled('div', {
  base: {
    flexCol: 'BETWEEN_CENTER',
    width: '100%',
  },
});

export const ColBetweenStart = styled('div', {
  base: {
    flexCol: 'BETWEEN_START',
    width: '100%',
  },
});

export const ColEvenlyCenter = styled('div', {
  base: {
    flexCol: 'EVENLY_CENTER',
    width: '100%',
  },
});

export const ColAroundCenter = styled('div', {
  base: {
    flexCol: 'AROUND_CENTER',
    width: '100%',
  },
});

export const ColStretchCenter = styled('div', {
  base: {
    flexCol: 'STRETCH_CENTER',
    width: '100%',
  },
});

export const ColCenterStart = styled('div', {
  base: {
    flexCol: 'CENTER_START',
    width: '100%',
  },
});

export const ColCenterEnd = styled('div', {
  base: {
    flexCol: 'CENTER_END',
    width: '100%',
  },
});

export const ColCenterBetween = styled('div', {
  base: {
    flexCol: 'CENTER_BETWEEN',
    width: '100%',
  },
});

export const ColCenterEvenly = styled('div', {
  base: {
    flexCol: 'CENTER_EVENLY',
    width: '100%',
  },
});

export const ColCenterAround = styled('div', {
  base: {
    flexCol: 'CENTER_AROUND',
    width: '100%',
  },
});

export const ColCenterStretch = styled('div', {
  base: {
    flexCol: 'CENTER_STRETCH',
    width: '100%',
  },
});

export const ColStartStart = styled('div', {
  base: {
    flexCol: 'START_START',
    width: '100%',
  },
});

export const ColStartEnd = styled('div', {
  base: {
    flexCol: 'START_END',
    width: '100%',
  },
});

export const ColStartStretch = styled('div', {
  base: {
    flexCol: 'START_STRETCH',
    width: '100%',
  },
});

// Row
export const RowCenter = styled('div', {
  base: {
    flexRow: 'CENTER',
    width: '100%',
  },
});

export const RowStartCenter = styled('div', {
  base: {
    flexRow: 'START_CENTER',
    width: '100%',
  },
});

export const RowEndCenter = styled('div', {
  base: {
    flexRow: 'END_CENTER',
    width: '100%',
  },
});

export const RowBetweenCenter = styled('div', {
  base: {
    flexRow: 'BETWEEN_CENTER',
    width: '100%',
  },
});

export const RowBetweenStart = styled('div', {
  base: {
    flexRow: 'BETWEEN_START',
    width: '100%',
  },
});

export const RowBetweenEnd = styled('div', {
  base: {
    flexRow: 'BETWEEN_END',
    width: '100%',
  },
});

export const RowEvenlyCenter = styled('div', {
  base: {
    flexRow: 'EVENLY_CENTER',
    width: '100%',
  },
});

export const RowAroundCenter = styled('div', {
  base: {
    flexRow: 'AROUND_CENTER',
    width: '100%',
  },
});

export const RowStretchCenter = styled('div', {
  base: {
    flexRow: 'STRETCH_CENTER',
    width: '100%',
  },
});

export const RowCenterStart = styled('div', {
  base: {
    flexRow: 'CENTER_START',
    width: '100%',
  },
});

export const RowCenterEnd = styled('div', {
  base: {
    flexRow: 'CENTER_END',
    width: '100%',
  },
});

export const RowCenterEvenly = styled('div', {
  base: {
    flexRow: 'CENTER_EVENLY',
    width: '100%',
  },
});

export const RowCenterAround = styled('div', {
  base: {
    flexRow: 'CENTER_AROUND',
    width: '100%',
  },
});

export const RowCenterStretch = styled('div', {
  base: {
    flexRow: 'CENTER_STRETCH',
    width: '100%',
  },
});

export const RowStartStart = styled('div', {
  base: {
    flexRow: 'START_START',
    width: '100%',
  },
});

export const RowStartStretch = styled('div', {
  base: {
    flexRow: 'START_STRETCH',
    width: '100%',
  },
});

export const RowStartEnd = styled('div', {
  base: {
    flexRow: 'START_END',
    width: '100%',
  },
});

export default {
  Center,

  // Col
  ColCenter,
  ColStartCenter,
  ColEndCenter,
  ColBetweenCenter,
  ColEvenlyCenter,
  ColAroundCenter,
  ColStretchCenter,
  ColCenterStart,
  ColCenterEnd,
  ColCenterBetween,
  ColCenterEvenly,
  ColCenterAround,
  ColCenterStretch,
  ColStartStart,

  // Row
  RowCenter,
  RowStartCenter,
  RowEndCenter,
  RowBetweenCenter,
  RowBetweenStart,
  RowEvenlyCenter,
  RowAroundCenter,
  RowStretchCenter,
  RowCenterStart,
  RowCenterEnd,
  RowCenterEvenly,
  RowCenterAround,
  RowCenterStretch,
  RowStartStart,
  RowStartEnd,
};
